<template>
  <div>
    <CContainer fluid>
      <IkoCartas
        :cartas="cartas"
        name="brand"
        :loading="loading"
        @deleteCarta="eliminarCarta"
        @criarModal="criarModal"
        @editarModal="editarModal"
      ></IkoCartas>
    </CContainer>

    <CModal
      :title="`${modal.type} Brand`"
      :color="modal.type === 'Edit' ? 'warning' : 'success'"
      :show.sync="modal.open"
    >
      <CCol sm="12">
        <CInput
          v-model="form.name"
          label="Name"
          placeholder="Brand Name"
        />
      </CCol>

      <template #footer>
        <CButton @click="modal.open = false" color="danger">Cancel</CButton>
        <CButton @click="modalSubmit" :disabled="aSubmeter" color="success">Submit</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import {postBrand, getBrands, deleteBrand, putBrand} from '@/api/brands'
import IkoCartas from '@/components/IkoCartas.vue';
export default {
  name: 'Brands',
  components: {
    IkoCartas,
  },
  data: () => ({
    modal: {
      type: 'Edit',
      open: false
    },
    form: {},
    aSubmeter: false,
    loading: true,
    cartas: []
  }),
  methods: {
    editarModal(idx) {
      this.form = {...this.cartas[idx].data};
      this.modal.open = true;
      this.modal.type = 'Edit';
    },
    criarModal() {
      this.form = {};
      this.modal.open = true;
      this.modal.type = 'New';
    },
    addCarta(carta) {
      this.cartas.push({
        data: carta,
        header: carta.name,
        text: `<b>Products<b/>: ${carta.products.length}`,
        to: [{ display: 'Products', name: 'BrandProducts', params: { brand: carta.name }}]
      });
    },
    modalSubmit() {
      this.aSubmeter = true;
      if(this.modal.type === 'Edit') {
        putBrand(this.form.id, this.form).then(() => {
          this.carregarCartas();
          this.form = {};
          this.modal.open = false;
          this.aSubmeter = false;
        }).then(() => this.aSubmeter = false);
      } else {
        postBrand(this.form).then((brand) => {
          this.addCarta(brand);
          this.form = {};
          this.modal.open = false;
          this.aSubmeter = false;
        }).then(() => this.aSubmeter = false);
      }
    },
    eliminarCarta(idx) {
      deleteBrand(this.cartas[idx].data.id).then(() => {
        this.cartas.splice(idx, 1);
        this.aSubmeter = false;
        this.eliminarModal = false;
      });
    },
    carregarCartas() {
      this.loading = true;
      this.cartas = [];
      getBrands(['products']).then((brands) => {
        brands = brands.sort((a, b) => a.name - b.name);
        brands.forEach(brand => this.addCarta(brand));
        this.loading = false;
      }).catch(() => this.loading = false);
    },
  },
  mounted() {
    this.carregarCartas();
  }
}
</script>
