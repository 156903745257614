import request from '@/utils/request';

export function getBrands(options) {
  return request({
    url: `/brands${options !== undefined ? `?options${options.length ? `=${options.join(encodeURIComponent('+'))}` : ''}` : ''}`,
    method: 'get',
  });
}

export function getBrand(brand) {
  return request({
    url: `/brands/${brand}`,
    method: 'get',
  });
}

export function postBrand(data) {
  return request({
    url: '/brands',
    method: 'post',
    data
  })
}

export function putBrand(brand, data) {
  return request({
    url: `/brands/${brand}`,
    method: 'put',
    data
  })
}

export function deleteBrand(brand) {
  return request({
    url: `/brands/${brand}`,
    method: 'delete'
  })
}